import * as React from "react"

function SvgPrincebazawuleLogo({ title, titleId, ...props }) {
  return (
    <svg
      width={45}
      height={45}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      className="{fill} dark:fill-lightGreen hover:fill-gray dark:hover:fill-white scale-100 xl:scale-125 hover:scale-125 xl:hover:scale-150 rotate-0 hover:rotate-6 transition duration-200"
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.29 1.475c-8.556-3.816-18.586.027-22.402 8.583L1.475 17.71c-3.816 8.556.027 18.586 8.583 22.402l7.652 3.413c8.556 3.816 18.586-.027 22.402-8.584l3.413-7.651c3.816-8.556-.027-18.586-8.584-22.402L27.29 1.475zm5.164 18.227c-.739-.468-1.59-.701-2.556-.701-.754 0-1.44.158-2.058.475a3.317 3.317 0 00-1.403 1.289v-4.953c0-.453-.15-.815-.452-1.086-.302-.272-.701-.407-1.199-.407-.527 0-.957.143-1.289.43-.317.286-.475.663-.475 1.13v13.028c0 .483.15.875.453 1.177.316.286.73.43 1.243.43.513 0 .92-.144 1.222-.43.301-.302.452-.694.452-1.177v-.18a3.535 3.535 0 001.425 1.357c.618.316 1.312.475 2.081.475.95 0 1.794-.242 2.533-.724.754-.498 1.335-1.184 1.742-2.058.422-.89.633-1.908.633-3.054s-.211-2.149-.633-3.008c-.407-.875-.98-1.546-1.72-2.013zm-1.697 7.419c-.437.558-1.055.837-1.854.837-.8 0-1.418-.272-1.855-.815-.437-.558-.656-1.35-.656-2.375 0-1.01.219-1.786.656-2.33.437-.557 1.056-.836 1.855-.836.799 0 1.417.271 1.855.814.437.528.655 1.297.655 2.307 0 1.026-.218 1.825-.655 2.398zm-11.066-7.374c-.739-.497-1.583-.746-2.533-.746-.77 0-1.463.166-2.081.497a3.388 3.388 0 00-1.425 1.335v-.181c0-.483-.15-.867-.452-1.154-.302-.301-.71-.452-1.222-.452s-.927.15-1.244.452c-.301.287-.452.671-.452 1.154v12.35c0 .467.158.844.475 1.13.332.287.761.43 1.289.43.498 0 .897-.136 1.199-.407.301-.272.452-.633.452-1.086v-4.275c.332.558.8.996 1.402 1.312a4.633 4.633 0 002.059.453c.965 0 1.817-.234 2.555-.701.74-.468 1.312-1.131 1.72-1.99.422-.875.633-1.886.633-3.032 0-1.146-.211-2.156-.633-3.03-.408-.89-.988-1.576-1.742-2.059zm-1.674 7.419c-.437.528-1.055.791-1.854.791-.8 0-1.418-.27-1.855-.814-.438-.558-.656-1.342-.656-2.352 0-1.025.219-1.81.656-2.352.437-.558 1.055-.837 1.855-.837.799 0 1.417.286 1.854.86.437.557.656 1.349.656 2.374 0 1.01-.219 1.787-.656 2.33z"
        fill=""
      />
    </svg>
  )
}

export default SvgPrincebazawuleLogo
